<template>
  <div>
    <div class="tab_wrap">
      <div @click="choose(100)" :class="current == 100 ? 'active' : ''">所有订单</div>
      <!-- <div @click="choose(108)" :class="current == 108 ? 'active' : ''">
        待确认 <span>{{ info.order108_num }}</span>
      </div> -->
      <div @click="choose(101)" :class="current == 101 ? 'active' : ''">
        待付款 <span>{{ info.order101_num }}</span>
      </div>
      <div @click="choose(102)" :class="current == 102 ? 'active' : ''">
        待发货 <span>{{ info.order102_num }}</span>
      </div>
      <div @click="choose(103)" :class="current == 103 ? 'active' : ''">
        待收货 <span>{{ info.order103_num }}</span>
      </div>
      <div @click="choose(105)" :class="current == 105 ? 'active' : ''">
        已完成 <span>{{ info.order105_num }}</span>
      </div>
      <div @click="choose(106)" :class="current == 106 ? 'active' : ''">
        已取消 <span>{{ info.order106_num }}</span>
      </div>
      <div @click="choose(104)" :class="current == 104 ? 'active' : ''">
        待退款 <span>{{ info.order104_num }}</span>
      </div>
    </div>
    <el-form :inline="true" :model="form" class="form">
      <el-form-item label="商品名称：">
        <el-input v-model="form.kw" placeholder="商品名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="订单编号：">
        <el-input v-model="form.order_no" placeholder="订单编号" clearable></el-input>
      </el-form-item>
      <el-form-item label="订单时间：">
        <el-select v-model="form.order_time" placeholder="请选择" clearable>
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button class="custombtn" type="primary" @click="getOrder">筛选订单</el-button>
      </el-form-item>
    </el-form>
    <div class="order_main">
      <div class="item" v-for="item in list" :key="item.id">
        <div class="item_top flex_sp_c">
          <div class="flex_row_aic">
            <p class="til">{{ item.status_str }}</p>
            <p class="c6">
              订单号：<span class="c3">{{ item.orderno }}</span>
            </p>
            <p class="c6">
              订单时间：<span class="c3">{{ item.addtime }}</span>
            </p>
            <p class="c6" v-if="item.erplzzt">
              流转状态：<span class="c3">{{ item.erplzzt }}</span>
            </p>
            <!-- <span class="c3" style="margin-right: 10px">胡汉三</span>
            <span class="red">暂无</span> -->
          </div>
          <span class="pr">￥{{ item.total_price }}</span>
        </div>
        <div class="item_cen flex_sp">
          <div class="flex_row left_box">
            <div class="img_box" v-for="sub in item.goods" :key="sub.id">
              <div>
                <img style="width: 110px; height: 110px" :src="sub.image.url" alt="" />
                <p class="c3 shenglue_1">{{ sub.title }}</p>
                <p class="p1">
                  ￥{{ sub.price }}
                  <span class="c6" style="font-size: 14px"> x{{ sub.number }} </span>
                </p>
              </div>
            </div>
          </div>
          <div style="width: 90px">
            <div v-for="sub in item.buttons" :key="sub.type" @click="btnClick(sub.type, item)" class="btn flex_c_c c3">{{ sub.value }}</div>
            <div class="btn flex_c_c c3" @click="handleDetail(item.orderno)">查看明细</div>
          </div>
        </div>
      </div>
    </div>
    <pagination :total="total" :currentPage="page" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";
import { handleData } from "@/utils";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      current: 100,
      info: {},
      form: {
        kw: "",
        order_no: "",
        order_time: "",
      },
      options: [],
      list: [],
      total: 0,
      page: 1,
      page_size: 10,
    };
  },
  created() {
    let index = this.$route.query.index;
    this.current = index || 100;
    this.getOrder();

    this.$api("account.getOrderTime").then((res) => {
      this.options = res.data;
    });
  },
  methods: {
    getOrder() {
      this.$api("account.getOrder", {
        ...this.form,
        status: this.current,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        console.log("订单列表---", res);
        this.list = res.data;
        this.total = res.listTotal;
      });
      this.getTotal();
    },
    getTotal() {
      this.$api("account.getMyInfo").then((res) => {
        console.log("订单数据---", res);
        this.info = res.data;
      });
    },
    choose(i) {
      this.current = i;
      this.page = 1;
      this.getOrder();
    },
    handleDetail(id) {
      this.$router.push({
        path: "/index/subRouter/orderDetail",
        query: { id },
      });
    },
    // 101取消订单 102立即支付 103确认收货 104再次购买 105申请售后  106物流跟踪
    async btnClick(type, item) {
      if (type == 101) {
        await handleData("account.cancelOrder", { id: item.id }, "取消订单");
      }
      if (type == 102) {
        //立即支付
        this.$api("cart.weixinpay", { ddbh: item.orderno }).then((res) => {
          if (res.data.code == "101") {
            this.get_ddzt(item);
            // 获取到支付二维码

            var centent = "<div style='display: flex;justify-content: center;'><img style='width: 200px; height: 200px' src="+res.data.code_url+"  /></div>";

            this.$confirm(centent, res.data.type+'支付二维码', {
              dangerouslyUseHTMLString: true,
              confirmButtonText: '确定',
              showCancelButton: false,  //是否显示取消按钮
              showClose: false, //是否显示右上角的x
              closeOnClickModal: false, //是否可以点击空白处关闭弹窗
            }).then(() => {
              clearInterval(this.lx_ddzt);
              this.$router.replace({
                path: "/index/subRouter/order",
                query: { index: 101 },
              });
            });

          } else {
            this.$alert(res.data.err, "错误", {
              confirmButtonText: "确定",
              callback: () => {},
            });
          }
        });
      }
      if (type == 103) {
        await handleData("account.deliveryOrder", { id: item.id }, "确认收货");
      }
      if (type == 104) {
        this.$api("account.buyTwice", { order_id: item.orderno }).then(() => {
          this.$message.success("已加入购物车");
          this.$store.dispatch("getBadge");
        });
      }
      if (type == 105) {
        this.$router.push({
          name: "ReturnGoods",
          params: { orderno: item.orderno, id: item.id },
        });
      }
      this.getOrder();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getOrder();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getOrder();
    },

    //轮询获取订单状态
    get_ddzt(item){
      let that = this;
      that.lx_ddzt = setInterval(() => {
        that.$api("cart.getOrderState", { id: item.orderno }).then((res) => {
          console.log('轮询',res.data.pay_info.need_pay);
          if(res.data.pay_info.need_pay == "102"){
            clearInterval(that.lx_ddzt);
            that.$msgbox.close();

            that.getOrder();

            // that.$router.replace({
            //   path: "/index/subRouter/order",
            //   query: { index: 102 },
            // });
          }
        })

      }, 2000);
    }

  },
};
</script>

<style lang="less" scoped>
.tab_wrap {
  width: 100%;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  border-bottom: 1px solid #eeeeee;
  div {
    margin-right: 36px;
    padding-bottom: 20px;
    cursor: pointer;
    &.active {
      color: @themeColor;
      font-weight: bold;
      border-bottom: 2px solid @themeColor;
    }
  }
  span {
    color: @themeColor;
    margin-left: 5px;
  }
}
.form {
  margin-top: 20px;
  .el-button {
    background-color: @themeColor;
    border-color: @themeColor;
  }
}
.order_main {
  .item {
    width: 950px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-bottom: 20px;
    .item_top {
      height: 50px;
      background: #f3f3f3;
      padding: 0 15px;
      .til {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
      }
      p {
        margin-right: 20px;
      }
      .red {
        color: @priceRed;
      }
      .pr {
        font-size: 18px;
        font-weight: bold;
        color: @priceRed;
      }
    }
    .item_cen {
      padding: 20px;
      .left_box {
        flex: 1;
        overflow-x: auto;
        margin-right: 40px;
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(40, 44, 52, 0.1);
          border: 3px solid transparent;
          border-radius: 7px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(40, 44, 52, 0.1);
        }
      }
      .img_box {
        margin-right: 20px;
        .p1 {
          font-size: 16px;
          font-weight: 400;
          color: @priceRed;
        }
      }
    }
    .btn {
      width: 87px;
      height: 33px;
      border: 1px solid #cdcdcd;
      border-radius: 4px;
      margin-bottom: 20px;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
